import React, { Component } from "react";

// Breakpoints
import * as Breakpoints from "../../_core/utils/Breakpoints";

// Core
//import {FadeIn,Loader,Image,Form,Html,Link,Base,Map,List,IsVisible,Grid,Media,ModifierSection,Section,Window,Content} from '../../_core/components';

import SiteModifier from "../../_core/components/SiteModifier";
import FadeIn from "../../_core/components/FadeIn";
import Loader from "../../_core/components/Loader";
import Image from "../../_core/components/Image";
import Link from "../../_core/components/Link";
import ModifierSection from "../../_core/components/ModifierSection";
import Content from "../../_core/components/Content";
import Media from "../../_core/components/Media";
import Grid from "../../_core/components/Grid";
import Base from "../../_core/components/BaseComponent";
import Html from "../../_core/components/Html";
import Form from "../../_core/components/Form";

import { Entry, Asset } from "../../_core/models";
import { Each } from "../../_core/utils";
import lightOrDark from "../../_core/utils/lightOrDark";
import Gallery from "../../_core/components/Gallery";
import Template from "../../_core/controllers/Template";

import VisibilitySensor from "react-visibility-sensor";

// Helpers
import "../../_core/helpers/String";

// Conf
import conf from "../../_core/_conf";

// Project
import OpacityScreen from "../OpacityScreen";
import CoverFlow from "../CoverFlow";
import Slider from "../Slider";
import Logo from "../Logo";

class Javascript extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = this.props.src;
    this.instance.appendChild(s);
  }

  render() {
    return <div ref={(el) => (this.instance = el)} />;
  }
}

class Blocks extends Component {
  sections = [];
  componentDidMount = () => {};
  cleanUri = (uri) => {
    let settings = conf.get();
    return uri.replace(settings.CDN_HOSTNAME, "");
  };
  makeBlocks = () => {
    this.section = [];
    let blocks = this.props.blocks || [];

    if (!blocks.length) {
      this.sections = [];
      return;
    }

    // Get the background positions...
    if (blocks[0].type != "background")
      blocks.unshift({
        type: "background",
        style: "none",
      });

    let sections = [];
    let section = -1;

    blocks.forEach((block) => {
      if (block.type == "background") {
        section++;
        sections[section] = { ...block, blocks: [] };
      } else {
        sections[section].blocks.push(block);
      }
    });

    this.sections = sections;
  };
  getBlock = (origBlock, entry, ix) => {
    let _this = this;
    let settings = conf.get();

    let block = { ...origBlock };
    let blockId = block.id || entry.id + "_block_" + ix;

    switch (block.type) {
      case "globalBlock":
        return (
          <Entry
            id={block.block}
            render={(globalBlock) => (
              <Each
                items={globalBlock.pageContent}
                render={(item) => _this.getBlock(item, entry)}
              />
            )}
          />
        );

      case "anchor":
        return (
          <a
            className="Block Block--anchor"
            id={block.name}
            name={block.name}
          ></a>
        );
      case "divider":
        return <div className="Block Block--divider" id={blockId}></div>;
      case "include":
        let includeUrl = block.page.url.replace(/https?:\/\//, "");
        let segments = includeUrl.split("/");
        segments.shift();
        let lookupUrl = `/${segments.join("/")}`;

        return (
          <Entry
            id={[]}
            filter={(entry) => entry.uri == lookupUrl}
            render={(include) => {
              return (
                <Template
                  entry={include}
                  template={
                    include.template == "page" ? "default" : include.template
                  }
                />
              );
            }}
          />
        );
      case "masthead":
        return (
          <VisibilitySensor
            partialVisibility
            offset={{ top: 50 }}
            onChange={(isVisible) =>
              isVisible
                ? window.site.modify("dark")
                : window.site.deModify("dark")
            }
          >
            <div
              className="Block Block--masthead"
              style={{
                height: window.innerHeight,
                backgroundColor: block.colour,
              }}
              id={blockId}
              data-type={block.type}
            >
              <div className="Block-media">
                <Media id={block.image.id} fluid={true} lay={true} />
              </div>

              <div className="Block-content">
                <h1>{block.text}</h1>
              </div>

              <div
                className="Block-screen"
                style={{ backgroundColor: block.colour }}
              >
                <div className="Block-logo">
                  <Logo />
                </div>
              </div>
            </div>
          </VisibilitySensor>
        );

      case "gallery":
        if (block.style == "slider") {
          return (
            <div className="Block Block--swiper">
              <div className="Block-media">
                <div className="Block-swiper">
                  <Slider
                    items={block.images.map((item, ix) => (
                      <div
                        data-href={item.linkTo || item.customLink}
                        className={`Item Item--gallery Item--swiper Item--${
                          item.image.width > item.image.height
                            ? "landscape"
                            : "portrait"
                        }`}
                        key={`slider_slide_${ix}`}
                      >
                        <div className="Item-media">
                          <Media asset={item.image} fluid={true} lazy={false} />
                        </div>
                        {(() => {
                          if (item.caption) {
                            return (
                              <div className="Item-body">
                                <h2 className="Item-caption">{item.caption}</h2>
                              </div>
                            );
                          }
                        })()}
                      </div>
                    ))}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="Block Block--gallery">
              <div className="Block-media">
                <div className="Grid Grid--gallery">
                  {block.images.map((item, ix) => (
                    <div key={`gallery_${ix}`}>
                      <Link to={item.linkTo || item.customLink}>
                        <div className={`Item Item--gallery Item--thumb`}>
                          <div className="Item-media">
                            <Media
                              asset={item.image}
                              fluid={true}
                              lazy={true}
                            />
                          </div>
                          {(() => {
                            if (item.caption) {
                              return (
                                <div className="Item-body">
                                  <h2 className="Item-caption">
                                    {item.caption}
                                  </h2>
                                </div>
                              );
                            }
                          })()}
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        }

      case "image":
        let images = block.images || [];
        return (
          <div
            className={"Block Block--image Block--" + block.style}
            id={blockId}
            data-type={block.type}
          >
            <div className="Block-content">
              {/* Image grid */}
              <Grid modifiers={[block.type, block.style, block.align]}>
                {images.map((image) => {
                  if (block.render)
                    return (
                      <div key={image.image} className="Grid-item">
                        {block.render(image)}
                      </div>
                    );

                  return (
                    <div
                      key={image.image}
                      className="Grid-item"
                      data-offset={image.offset ? "offset" : null}
                    >
                      <Media id={image.image} fluid={false} />
                      {(() => {
                        if (image.caption && image.caption != "") {
                          return (
                            <Content modifier="caption">
                              <p>{image.caption.decodeHTML()}</p>
                            </Content>
                          );
                        } else {
                          return null;
                        }
                      })()}
                    </div>
                  );
                })}
              </Grid>
            </div>
          </div>
        );

      case "textWithImage":
        return (
          <div
            className={"Block Block--textWithImage Block--" + block.style}
            id={blockId}
            data-type={block.type}
          >
            <div className="Block-media">
              <Media id={block.image} fluid={false} lazy={true} />
            </div>
            <div className="Block-content">
              <Content html={block.text} />
            </div>
          </div>
        );

      case "item":
        return (
          <div
            className={`Block Block--item Block--${block.style} Block--${block.layout}`}
            id={blockId}
            data-type={block.type}
          >
            {(() => {
              if (block.heading) {
                return (
                  <div className="Block-head">
                    <Content>
                      <h1>{block.heading}</h1>
                    </Content>
                  </div>
                );
              }
            })()}

            <div className="Block-body">
              <div className="Block-media">
                <Link to={block.linkTo || block.customLink}>
                  <Media id={block.image.id} fluid={false} lazy={true} />
                </Link>
              </div>
              <div className="Block-content">
                <Content html={block.summary} />
              </div>
            </div>
          </div>
        );

      case "text":
        return (
          <div
            className={"Block Block--text Block--" + block.align}
            id={blockId}
            data-type={block.type}
          >
            {(() => {
              if (block.heading) {
                return (
                  <div className="Block-head">
                    <Content>
                      <h1>{block.heading}</h1>
                    </Content>
                  </div>
                );
              }
            })()}
            <div className="Block-content">
              <Content html={block.text} />
            </div>
          </div>
        );

      case "newsletter":
        let formProps = {
          ajax: false,
          action:
            "https://facebook.us10.list-manage.com/subscribe/post?u=2597e9b142d7c1f5e898920df&id=5f46264c2d",
          method: "post",
          target: "_blank",
          fields: [
            {
              title: "Sign up to our newsletter",
              name: "EMAIL",
              type: "email",
              placeholder: "Your email address here...",
            },
            {
              name: "b_2597e9b142d7c1f5e898920df_5f46264c2d",
              type: "text",
              style: { position: "absolute", left: "-5000px" },
            },
          ],
          callToAction: "Submit",
        };
        return (
          <div
            className={"Block Block--form Block--newsletter"}
            id={blockId}
            data-type={block.type}
          >
            <div className="Block-content">
              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content modifier="article" html={block.text} />
                </div>
              </Grid>

              {/*<Form {...formProps} />*/}
              <div id="airship_signup_form"></div>
              <Javascript src="//eflyers.powertext.co.uk/forms/mjmk/kol_restaurant_signup.js" />
            </div>
          </div>
        );
      case "form":
        let required = block.fields.filter((field) => field.required);

        return (
          <div
            className={
              "Block Block--form Block--enquiry Block--" +
              block.size +
              " Block--" +
              block.style
            }
            id={blockId}
            data-type={block.type}
          >
            <div className="Block-content">
              {/* Text / Article */}

              {(() => {
                if (block.heading || block.text) {
                  return (
                    <Grid modifiers={["article"]}>
                      <div className="Grid-item">
                        {block.heading && (
                          <Content html={`<h3>${block.heading}</h3>`} />
                        )}
                        <Content html={block.text} />
                      </div>
                    </Grid>
                  );
                }
              })()}

              <Form
                modifier={"enquiry"}
                onSubmit={(data) => {}}
                onSuccess={(response, data, form) => {
                  form.setContent(
                    <div className="Form-response">
                      <SiteModifier auto={true} modifier="response" />
                      <Content html={block.message} />
                    </div>
                  );
                }}
                submit={block.submit || "Send"}
                ajax={true}
                action={"//send.webcdn.network/api/cFJCArop2mYm1LBF7UtY"} // kol
                //action={`//send.webcdn.network/api/E3oq1SB3oVarOWF0XaBA`} // matt@un-work.co
                fields={block.fields
                  .map((field) => {
                    let f = { ...field };

                    if (field.type == "Single Line") f.type = "text";
                    if (field.type == "Multi Line") f.type = "textarea";
                    if (field.type == "Checkbox") f.type = "checkbox";
                    if (field.type == "Dropdown") f.type = "select";
                    if (field.type == "Date") f.type = "date";

                    //if(!f.placeholder || f.placeholder == '') f.placeholder = f.inputName;

                    //f.name = f.inputName.replace(/[\s]+/gi,'_').toLowerCase();
                    f.modifier = f.name;

                    return f;
                  })
                  .concat(
                    required.map((field) => {
                      let rule =
                        field.name.toLowerCase() == "email"
                          ? "required|valid_email"
                          : "required";
                      return {
                        name: `_valid[${field.name}]`,
                        value: rule,
                        type: "hidden",
                      };
                    })
                  )
                  .concat([
                    { name: "website", type: "hidden", value: "KOL" },
                    {
                      name: "sender",
                      type: "hidden",
                      value: "kolrestaurant.com",
                    },
                    // Note the must be an 'email' field for the API to send a confirmation. Case sensitive.
                    {
                      name: "_email_from",
                      type: "hidden",
                      value: "events@kolrestaurant.com",
                    },
                    { name: "_email_from_name", type: "hidden", value: "KOL" },
                    {
                      name: "_email_confirmation_message",
                      type: "hidden",
                      value: `Thank you for your message, we have received your enquiry and will get back to you shortly.<br />
If you have any additional information that you think will help us to assist you, please feel free to reply to this email.<br /><br />
Kind Regards,<br />
The Events Team at KOL`,
                    },
                    { name: "_json", type: "hidden", value: "1" },
                  ])}
              ></Form>
              {/*
                  <Form method={block.method||null} ajax={block.ajax||null} target={block.target||null} action={block.action||null} fields={block.fields} submit={block.callToAction} />*/}
            </div>
          </div>
        );

      default:
        return (
          <div
            data-type={block.type}
            id={blockId}
            className={"Block Block--" + block.type + " Block--" + block.size}
          >
            {JSON.stringify(block)}
          </div>
        );
    }
  };
  render() {
    this.makeBlocks();
    //console.log('blocks',this.sections);

    let { sections } = this;
    let modifiers = this.props.modifiers || [];
    let entry = this.props.entry || false;

    return (
      <Base family="Blocks" modifiers={modifiers}>
        {sections.map((section, s_ix) => {
          let style = section.style || "default";
          let color = section.background
            ? { backgroundColor: section.background }
            : null;

          let modifier = "color-default";
          if (color) {
            style += " Blocks-section--";
            let lod = lightOrDark(section.background);
            style += lod;

            modifier = lod;
          }

          return (
            <div
              className={"Blocks-section Blocks-section--" + style}
              key={"block_section_" + s_ix}
              style={color}
              data-background={section.background}
              data-style={style}
            >
              <ModifierSection modifier={modifier} pos={30}>
                {section.blocks.map((block, ix) => {
                  return (
                    <React.Fragment key={"block_frag_" + ix}>
                      {this.getBlock(block, entry, ix)}
                    </React.Fragment>
                  );
                })}
              </ModifierSection>
            </div>
          );
        })}
      </Base>
    );
  }
}

function Block(block) {}

export default Blocks;
/*
  {blocks.map((block) => {

            if(block.type == 'text')
            {
              return (
                <Content html={block.body}/>
                )
            } else if(block.type == 'columns')
            {
              return (
              <div>
              <Content html={block.body}/>
              <div className="Template-column">
                <Content html={block.leftColumn}/>
              </div>
              <div className="Template-column">
                <Content html={block.rightColumn}/>
              </div>
              </div>)
            }

            })}
*/
